import { Config, DriveStep, Driver, Popover as DriverPopover, State } from "driver.js";

export type Popover = DriverPopover;

export type Step = DriveStep & {
    element?: string;
    popover: Popover;
    meta?: Record<string, any>;
};

export type WalkthroughConfig = Config & {
    enabled?: boolean;
    // walktroughEl: string;
    steps: Step[];
};

export type Walkthrough = {
    [pageId: string]: WalkthroughConfig;
};

export type DriverState = State & {
    activeStep?: Step;
    previousStep?: Step;
}

export type WalkthroughOpts = {
    config: Config;
    state: State;
    driver: Driver;
}

export const walkthroughPageId = {
    STOCK_LIST: "STOCK_LIST",
    CART: "CART",
    CHECKOUT: "CHECKOUT",
    ORDER_LIST: "ORDER_LIST",
}