import {
  pathOverrideKeys,
  useQueryClientService,
} from '../hooks/useQueryCustomHooks/useDataService';

export enum AnalyticsEventsType {
  PURCHASE_MADE = 'purchase',
  VIEW_ITEM_LIST = 'viewItemList',
  VIEW_CART = 'viewCart',
  ADD_TO_CART = 'addToCart',
  REMOVE_FROM_CART = 'removeFromCart',
  VIEW_ITEM = 'viewItem',
  BEGIN_CHECKOUT = 'beginCheckout',
  CREATE_ACCOUNT = 'createAccount',
  WALKTHROUGH_CLOSED = 'walkthroughClosed',
  WALKTHROUGH_OPENED = 'walkthroughOpened',
  WALKTHROUGH_PREVIOUS_BTN_CLICK = 'walkthroughPreviousBtnClick',
  WALKTHROUGH_NEXT_BTN_CLICK = 'walkthroughNextBtnClick',
}

export const updateAnalyticsEventCache = (
  setQueryCacheData: ReturnType<typeof useQueryClientService>['setQueryCacheData'],
  payload: any,
  updateKey: AnalyticsEventsType,
  updaterFn?: any
) => {
  const queryKey = pathOverrideKeys.analyticsEvents;

  const fn =
    updaterFn ||
    ((prev: any) => ({
      ...prev,
      [updateKey]: payload ?? undefined,
    }));

  setQueryCacheData(queryKey, null, fn);
};
