import { PopoverDOM } from "driver.js";
import { tryCatchWrapper } from "../../_lib/util";
import { sendErrorToRum } from "../ErrorHandlers/ErrorUtils";
import { DriverState, WalkthroughConfig, WalkthroughOpts } from "./WalkthroughType";
import { AnalyticsEventsType, updateAnalyticsEventCache } from "../../widgets/googleAnalyticsUtils";

export const parseWalkthroughContent = (I18n: Record<string, any>, str: string) => {
    // return _.template(str, {
    //     interpolate: /\${([\s\S]+?)}/g,
    //     imports: {
    //       I18n: (...str) => {
    //         console.log(str);
    //         return str;
    //       }
    //     }
    //   })
    return str.replace(/\${([\s\S]+?)}/g, (matchTxt) => {
        const i18nText = matchTxt.match(/'([^']*)'/),
            i18nKey = matchTxt.match(/\${I18n\.([\w]+)\s*\|\|.*}/);
        // console.log(i18nText, i18nKey, matchTxt);
        if (!i18nText?.[1] || !i18nKey?.[1]) return matchTxt;
        return I18n[i18nKey[1]] || i18nText[1];
    })
}

export const getUserId = (userState: any) => userState?.userId;
export const getCurrentStepId = (state: DriverState) => state.activeStep?.meta?.stepId;
export const previousStepId = (state: DriverState) => state.previousStep?.meta?.stepId;
export const nextStepId = (state: DriverState, config: WalkthroughConfig) => {
    const currentStepIdx = config.steps.findIndex(
        (step) => (step as any).meta?.stepId === getCurrentStepId(state)
    );
    return config.steps[currentStepIdx + 1]?.meta?.stepId;
};
export const sendWalkthroughErrorEventToRum = (error: any) => sendErrorToRum(error, 'WALKTHROUGH_ERROR');

export const getAnalyticsTriggerEventFn = (setQueryCacheData: any) => (event: AnalyticsEventsType, payload: any) =>
    updateAnalyticsEventCache(setQueryCacheData, payload, event);

export const onWalkthroughPopoverRender = (walkthroughRefreshes: React.MutableRefObject<Record<string, boolean>>) =>
    (popover: PopoverDOM, opts: WalkthroughOpts) => {
        const imgs = popover.description.querySelectorAll('img');
        console.log(imgs, 'imgs', opts.state.activeIndex);
        const stepIdx = opts.state.activeIndex;
        if (imgs.length && stepIdx && !walkthroughRefreshes.current[stepIdx]) {
            const loadedImgIdx = [];
            imgs.forEach((img, imgIdx, arr) => {
                img.onload = () => {
                    loadedImgIdx.push(imgIdx);
                    if (arr.length === loadedImgIdx.length) {
                        walkthroughRefreshes.current[stepIdx] = true;
                        opts.driver.refresh();
                    }
                };
            });
        }
    }

export const getAnalyticsEventPayload = (analyticsEvtType: AnalyticsEventsType, pageName: string, userState: any, opts?: WalkthroughOpts) => {
    switch (analyticsEvtType) {
        case AnalyticsEventsType.WALKTHROUGH_CLOSED:
            return {
                pageId: pageName,
                userId: getUserId(userState),
                from: getCurrentStepId(opts?.state as DriverState),
            };
        case AnalyticsEventsType.WALKTHROUGH_OPENED:
            return {
                pageId: pageName,
                userId: getUserId(userState),
            };
        case AnalyticsEventsType.WALKTHROUGH_PREVIOUS_BTN_CLICK:
            return {
                pageId: pageName,
                userId: getUserId(userState),
                from: getCurrentStepId(opts?.state as DriverState),
                to: previousStepId(opts?.state as DriverState),
            };
        case AnalyticsEventsType.WALKTHROUGH_NEXT_BTN_CLICK:
            return {
                pageId: pageName,
                userId: getUserId(userState),
                from: getCurrentStepId(opts?.state as DriverState),
                to: nextStepId(opts?.state as DriverState, opts?.config as WalkthroughConfig),
            };
        default:
            return {};
    }
}

export const triggerWalkthroughAnalyticsEvent = (triggerAnalyticsEvent: ReturnType<typeof getAnalyticsTriggerEventFn>, pageName: string, userState: any) => (analyticsEvtType: AnalyticsEventsType, opts?: WalkthroughOpts) => {
    return tryCatchWrapper(() => {
        triggerAnalyticsEvent(
            analyticsEvtType,
            getAnalyticsEventPayload(analyticsEvtType, pageName, userState, opts)
        );
    }, sendWalkthroughErrorEventToRum)
}