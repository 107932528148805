import React, { Suspense, useContext } from 'react';

import { useGetQueryCacheData } from '../../hooks/useQueryCustomHooks/useGetQueryCacheData';
import { publicApiMapping } from '../../hooks/useQueryCustomHooks/usePublicQueryStates';
import { useGetDataService } from '../../hooks/useQueryCustomHooks/useDataService';
import useDataService from '../../hooks/useDataService';
import { eMessageType } from '../../types/IMessageType';
import { Popover, Walkthrough as WalkthroughType } from './WalkthroughType';
import { parseWalkthroughContent } from './WalkthroughUtils';
import { I18nContext } from '../../I18n';
const WalkthroughImpl = React.lazy(() => import('./WalkthroughImpl'));

export interface WalkthroughProps {
  pageName: string;
}

function WalkthroughWrapper({ pageName }: WalkthroughProps) {
  const { openSnackBar } = useDataService();
  const config = useGetQueryCacheData<any>(publicApiMapping.config);
  const I18n = useContext(I18nContext);
  const parseI18nContent = (str: string) => parseWalkthroughContent(I18n, str);
  const parseI18nWithSideEffect = (
    popover: Popover,
    contentToParse: 'description' | 'title' | 'nextBtnText' | 'prevBtnText' | 'doneBtnText'
  ) => {
    if (popover[contentToParse])
      popover[contentToParse] = parseI18nContent(popover[contentToParse]);
  };
  const fetchUrl = async () => {
    const res = await fetch(config.walkthrough);
    if (!res.ok) {
      throw new Error('Failed to fetch walkthrough data');
    }
    const walkthrough: WalkthroughType = await res.json();
    if (!walkthrough) return {};
    Object.keys(walkthrough).forEach((page) => {
      for (const i in walkthrough[page].steps) {
        const popover = walkthrough[page].steps[i].popover;
        if (!popover) continue;
        parseI18nWithSideEffect(popover, 'description');
        parseI18nWithSideEffect(popover, 'title');
        parseI18nWithSideEffect(popover, 'nextBtnText');
        parseI18nWithSideEffect(popover, 'prevBtnText');
        parseI18nWithSideEffect(popover, 'doneBtnText');
      }
    });
    return walkthrough;
  };
  // return null if walkthrough is not enabled
  const { data: walkthrough } = useGetDataService<WalkthroughType>({
    url: config.walkthrough,
    queryFn: fetchUrl,
    onError: (error: any) => {
      openSnackBar(error.message, eMessageType.error);
    },
    staleTime: Infinity,
  });
  if (!walkthrough || !walkthrough[pageName]) return <></>;
  return (
    <Suspense fallback={<></>}>
      <WalkthroughImpl pageName={pageName} walkthrough={walkthrough[pageName]} />
    </Suspense>
  );
}

function Walkthrough({ pageName }: WalkthroughProps) {
  const config = useGetQueryCacheData<any>(publicApiMapping.config);
  // return null if walkthrough is not in config
  if (!config?.walkthrough) return <></>;

  return <WalkthroughWrapper pageName={pageName} />;
}

export default Walkthrough;
